<template>
  <div class="billboard">
    <div class="billboard__wrapper">
      <Column align="center">
        <slot name="billboard-title"/>
        <slot name="billboard-description"/>
        <slot name="billboard-buttons"/>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Billboard',
};
</script>
